import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Modal, Button } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <p>{`어드민에서만 사용됩니다. 모바일 사이즈를 고려하지 않았습니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Modal} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Modal\n  opener={<Button>Open Modal</Button>}\n  title=\"수강권 주문 상세\"\n  subTitle=\"수강권을 한번 더 확인해 주세요.\"\n  successText=\"확인\"\n  cancelText=\"취소\"\n>\n  I am Modal.\n</Modal>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Modal,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Modal opener={<Button mdxType="Button">Open Modal</Button>} title="수강권 주문 상세" subTitle="수강권을 한번 더 확인해 주세요." successText="확인" cancelText="취소" mdxType="Modal">
    I am Modal.
  </Modal>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      